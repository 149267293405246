/* participants-list */
.participants {
  min-height: 20px;
}
.participants .avatar {
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
}
.participants .add-participants {
  position: absolute;
  cursor: pointer;
  bottom: -3px;
}
.participants .add-participants i {
  font-size: 16px;
  color: #ff9800;
  border: 2px solid #fff;
  border-radius: 50%;
}
.participants .participant-avatar, .participants .add-participants {
  margin-right: -0.5rem;
}
/* file-participant-dialog */
.participant-add {
  display: flex;
  justify-content: space-between;
}
.participant-add .participant-select {
  width: 385px;
}
.participant-add .btn {
  width: 75px;
}
.participant-select-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.participant-select-avatar {
  margin-right: 10px;
}
.participant-select-name {
  height: 2rem;
  line-height: 2rem;
}
.participant-select-error {
  margin-top: 1em;
}
.participant-select .true__dropdown-indicator, .participant-select .true__indicator-separator {
  display: none;
}
.participant-select-info i {
  opacity: 0;
  margin-right: 10px;
}
.participant-select-info:hover i {
  cursor: pointer;
  opacity: 1;
  color: #a4a4a4;
}
